<template>
  <div class="head-container">
    <search-community ref="searchcommunity"></search-community>
    <el-input
      v-model="query.userName"
      clearable
      placeholder="客户昵称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  components: {
    searchCommunity,
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
