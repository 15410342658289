<template>
  <div class="app-container equityheader summary-page">
    <eHeader ref="header" :query="query" />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="siteName" label="所属社区"/>
      <el-table-column prop="userName" label="客户昵称"/>
      <el-table-column prop="amount" label="代币余额(USDT)"/>
      <el-table-column prop="balanceFrozen" label="已冻结(USDT)"/>
      <el-table-column prop="applyAmount" label="提币中(USDT)"/>
      <el-table-column prop="balance" label="可用余额(USDT)"/>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="+total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import initData from '@/mixins/initData'
  import eHeader from '@/components/financial/customerEquity/header'
  export default {
    components: { eHeader },
    mixins: [initData],
    data() {
      return {
        statistics: {
          "amount": '',// "总余额",
          "balance": '', //"可用余额",
          "balanceFrozen": '', //"冻结余额","
          balanceFrozen: '',//"申请中余额"}
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      beforeInit() {
        this.url = '/community/crm/getUserAssets'

        const query = this.query
        const userName = query.userName
        this.params = {
          page: this.page,
          size: this.size,
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(userName){
          this.params.userName = userName;
        }
        return true
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 2:
              sums[index] = `${this.statistics.amount}`;
              break;
            case 3:
              sums[index] = `${this.statistics.balanceFrozen}`;
              break;
            case 4:
              sums[index] = `${this.statistics.balanceFrozen}`;
              break;
            case 5:
              sums[index] = `${this.statistics.balance}`;
              break;
          }
        });

        return sums;
      },
    }
  }
</script>

<style lang="less" scoped>
.equityheader {

}
</style>
